/**
 * Copyright 2024 Vitaliy Zarubin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

 @import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

html, body {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: 'Rubik', sans-serif;
    line-height: 1.3;
    overflow: hidden;
}

#root.ScrollSection {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
}

a {
    outline: none;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
